
import DeptCard from './DeptCard.vue'
import {getCurrentInstance, reactive, toRefs,onBeforeMount, onMounted, defineComponent, provide} from 'vue';
import {TableColumnCtx} from "element-plus/es/components/table/src/table-column/defaults";
import DeptListUtil,{IDeptListDataObj} from "@/views/sysviews/organization/dept/deptListUtil";
import language from './deptLanguage'
export default defineComponent({
    name: "pageList",
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        const dataObj:IDeptListDataObj=reactive<IDeptListDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            pageListRef:null,
            importGuidParams:{
                ownerInst:proxy,
                uploadParams:{
                    saveType:0,
                    action:utils.Api.buildUrl('/dept/importData')
                },
                downloadParams:{
                    sourceName:'deptImportTemplate.xlsx',
                    downLoadFileName:proxy.$t('dept.downLoadFileName'),
                    action:utils.Api.buildUrl('/dept/downLoadResourceFile')
                }
            },
            corpTreeData: [],
            pageList: {
                queryParam: {},
                modelComp: DeptCard,
                modelMethod: utils.Api.buildUrl("/dept/pageData")
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new DeptListUtil(proxy,dataObj);
        })
        onMounted(async()=>{
            dataObj.corpTreeData = await utils.Api.corpTreeData();
        })

        const formatShow=(row:any, column:TableColumnCtx<any>, value:any, index:number)=>{
            let npbspNum=row.F_LEVEL*2;
            let pre="";
            for(let i=0;i<npbspNum;i++){
                pre=pre+"\u3000"
            }
            return pre+value;
        }
        //导入按钮事件
        const importHandler=()=>{
            dataObj.refMap.get('importGuid').importDataDialogVisible=true
        }

        const change=(n1:any,n2:any)=>{
            console.log(n1,n2);
        }
        return{
            ...toRefs(dataObj),formatShow,importHandler,change
        }
    },

    components: {}
});