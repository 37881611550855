import BaseBean from "@/utils/BaseBean";

export interface IDeptCardDataObj {
    utilInst:DeptCardUtil
    formRef:any
    corpTreeData:Array<any>
    parentTreeData:Array<any>
    compParams:any
    disabled:boolean
    form: any
    rules: TFormRule
}
export default class DeptCardUtil extends BaseBean{
    public dataObj:IDeptCardDataObj

    constructor(proxy:any,dataObj:IDeptCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    public async requestParentData(corpId:string):Promise<any>{
        if(!corpId)return ;
        this.dataObj.parentTreeData = await this.utils.Api.deptTreeData({
            corpId: corpId
        });
    }
    //改变状态
    public changeStatus(params:any):void{
        this.utils.Tools.configBox({
            message:'确定要执行该操作吗?',
            sureFn:async ()=> {
                let url = this.dataObj.compParams.modelPath + "/custom";
                let res = await this.utils.Api.postRequest({ url: url, params: params});
                if(res.result){
                    this.utils.Tools.success({ message: res.msg });
                    if (this.proxy.engine.engineParams.ownerComp && this.proxy.engine.engineParams.ownerComp.queryHandler) this.proxy.engine.engineParams.ownerComp.queryHandler();
                    await this.proxy.engine.engineUtil.doAddOrLoad(this.proxy.engine.id);
                }
            }
        });
    }
}