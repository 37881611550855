import BaseBean from "@/utils/BaseBean";

export interface IDeptListDataObj {
    utilInst:DeptListUtil
    pageListRef:any
    corpTreeData: Array<any>
    pageList:any
    refMap:Map<string,any>
    importGuidParams:any
}
export default class DeptListUtil extends BaseBean{
    public dataObj:IDeptListDataObj

    constructor(proxy:any,dataObj:IDeptListDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }

}