import { getCurrentInstance,reactive,toRefs,onBeforeMount,onMounted,defineComponent} from 'vue';
import DeptCardUtil,{IDeptCardDataObj} from "@/views/sysviews/organization/dept/deptCardUtil";

export default defineComponent({
    name: "deptCard",
    title: "部门管理",
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IDeptCardDataObj=reactive<IDeptCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            corpTreeData: [],
            parentTreeData: [],
            compParams: {
                modelPath: utils.Api.buildUrl("/dept")
            },
            disabled:false,
            form: {},
            rules: utils.UtilPub.commonFormValidRule([{code:proxy.$t('dept.form.code_rule')},{name:proxy.$t('dept.form.name_rule')},{corpId:proxy.$t('dept.form.corpId_rule')}])
        })
        onBeforeMount(()=>{dataObj.utilInst=new DeptCardUtil(proxy,dataObj)})
        onMounted(()=>{})
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            dataObj.corpTreeData = await utils.Api.corpTreeData();
            await dataObj.utilInst.requestParentData(res.data.corpId);
        }
        const startHandler=()=>{
            dataObj.utilInst.changeStatus({id: dataObj.form.id,type:'start',operateType:'changeStatus'});
        }
        const stopHandler=()=>{
            dataObj.utilInst.changeStatus({id: dataObj.form.id,type:'stop',operateType:'changeStatus'});
        }
        return{
            ...toRefs(dataObj),beforeOpen,startHandler,stopHandler
        }
    },

    components: {}
});