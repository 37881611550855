export default {
    zh:{
        deptCard:'部门管理',
        dept:{
            downLoadFileName:'部门导入模版.xlsx',
            queryParam:{
                code:'编码',
                code_placeholder:'请输入编码模糊匹配查询...',
                name:'名称',
                name_placeholder:'请输入名称模糊匹配查询...',
                corpId:'机构',
            },
            columns:{
                F_CODE:'编码',
                F_NAME:'名称',
                F_CORP_NAME:'机构',
                F_PARENT_NAME:'上级部门',
                F_STATUS:'状态',
            },
            form:{
                code:'编码',
                code_placeholder:'请输入编码',
                code_rule:'请输入编码',
                name:'名称',
                name_placeholder:'请输入名称',
                name_rule:'请输入名称',
                corpId:'机构',
                corpId_placeholder:'请选择机构',
                corpId_rule:'请选择机构',
                parentId:'上级部门',
                parentId_placeholder:'请选择上级机构',
                desc:'说明',
                desc_placeholder:'请输入说明',
            }
        }
    },
    en:{
        deptCard:'dept management',
        dept:{
            downLoadFileName:'deptImportTemplate.xlsx',
            queryParam:{
                code:'code',
                code_placeholder:'Please enter a code fuzzy matching query...',
                name:'name',
                name_placeholder:'Please enter a name fuzzy matching query...',
                corpId:'corp',
            },
            columns:{
                F_CODE:'code',
                F_NAME:'name',
                F_CORP_NAME:'corp',
                F_PARENT_NAME:'parent',
                F_STATUS:'status',
            },
            form:{
                code:'code',
                code_placeholder:'Please enter a code',
                code_rule:'Please enter a code',
                name:'name',
                name_placeholder:'Please enter a name',
                name_rule:'Please enter a name',
                corpId:'corp',
                corpId_placeholder:'Please select corp',
                corpId_rule:'Please select corp',
                parentId:'parent',
                parentId_placeholder:'Please select parent dept',
                desc:'explain',
                desc_placeholder:'Please enter explain',
            }
        }
    }
}